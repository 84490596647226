export const searchPostUnderPageNavScroll = (windowObject) => {
    if ($('#searchPostUnderPageNav').length === 0) return false;

    let startPos = 0;
    let winScrollTop = 0;

    // 初期化処理を関数化
    const initializePageNav = () => {
        const pageNav = $('#searchPostUnderPageNav');
        if (pageNav.length === 0) return null;
        const text = $('#searchPostUnderPageNavText');
        const img = $('#searchPostUnderPageNavImg');
        const imgItem = $('#searchPostUnderPageNavImgItem');
        const link = $('#searchPostUnderPageNavLink');
        const currentSrc = imgItem.attr("src");
        const directoryPath = getDirectoryPath(currentSrc);
        const main = $('#main');
        return { pageNav, text, img, imgItem, link, directoryPath, main };
    };

    // ディレクトリパスとファイル名の抽出を分離
    const getDirectoryPath = (src) => {
        const fileName = src.split("/").pop();
        return src.replace(fileName, "");
    };

    // スクロール時のスタイル適用を関数化
    const updatePageNavState = (scrollTop, mainHeight, directoryPath) => {
        if (jQuery(window).width() <= 768) {
            if (scrollTop >= 160) {
                pageNav.addClass('is-on');
            } else {
                pageNav.removeClass('is-on is-open');
                text.removeClass('is-open');
                img.removeClass('is-open');
                link.removeClass('is-open');
                imgItem.attr("src", `${directoryPath}search-post-under-page-nav__toggle-img_sp.svg`);
            }
    
            if (mainHeight < scrollTop) {
                pageNav.removeClass('is-on is-open');
                text.removeClass('is-open');
                img.removeClass('is-open');
                link.removeClass('is-open');
                imgItem.attr("src", `${directoryPath}search-post-under-page-nav__toggle-img_sp.svg`);
            }
        } else {
            if (scrollTop >= 160) {
                pageNav.addClass('is-on');
            } else {
                pageNav.removeClass('is-on');
            }
    
            if (mainHeight < scrollTop) {
                pageNav.removeClass('is-on');
            }
        }
    };

    const { pageNav, text, img, imgItem, link, directoryPath, main } = initializePageNav();
    if (!pageNav) return false;

    windowObject.on('scroll', function () {
        winScrollTop = $(this).scrollTop();

        if (winScrollTop >= startPos && winScrollTop >= 160) {
            pageNav.addClass('is-position');
        } else {
            pageNav.removeClass('is-position');
        }

        updatePageNavState(winScrollTop, main.outerHeight(), directoryPath);

        startPos = winScrollTop;
    });
};
