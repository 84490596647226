export const searchPostUnderPageNav = () => {
    if ($('#searchPostUnderPageNav').length === 0) return false;

    const pageNav = $('#searchPostUnderPageNav');
    const toggle = $('#searchPostUnderPageNavToggle');
    const toggleOpen = $('#searchPostUnderPageNavToggleOpen');
    const text = $('#searchPostUnderPageNavText');
    const img = $('#searchPostUnderPageNavImg');
    const imgItem = $('#searchPostUnderPageNavImgItem');
    const link = $('#searchPostUnderPageNavLink');
    const currentSrc = imgItem.attr("src");
    const currentFileName = currentSrc.split("/").pop();
    const directoryPath = currentSrc.replace(currentFileName, "");

    if (jQuery(window).width() > 768 && jQuery(window).width() <= 1200) {
        pageNav.addClass('is-hidden');
        toggle.addClass('is-hidden');
        text.addClass('is-hidden');
        img.addClass('is-hidden');
        link.addClass('is-hidden');
        imgItem.attr("src", directoryPath + 'search-post-under-page-nav__toggle-img_sp.svg');
    }

    if (jQuery(window).width() > 1200) {
        if (pageNav.hasClass('is-hidden')) {
            imgItem.attr("src", directoryPath + 'search-post-under-page-nav__toggle-img_sp.svg');
        } else {
            imgItem.attr("src", directoryPath + 'search-post-under-page-nav__toggle-img_close_sp.svg');
        }
    }

    toggle.on('click', function () {
        if (jQuery(window).width() <= 768) {
            if (pageNav.hasClass('is-open')) {
                pageNav.removeClass('is-open');
                text.removeClass('is-open');
                img.removeClass('is-open');
                link.removeClass('is-open');
                imgItem.attr("src", directoryPath + 'search-post-under-page-nav__toggle-img_sp.svg');
            } else {
                pageNav.addClass('is-open');
                text.addClass('is-open');
                img.addClass('is-open');
                link.addClass('is-open');
                imgItem.attr("src", directoryPath + 'search-post-under-page-nav__toggle-img_close_sp.svg');
            }
        } else if (jQuery(window).width() > 768 && jQuery(window).width() <= 1200) {
            if (pageNav.hasClass('is-hidden')) {
                pageNav.removeClass('is-hidden');
                toggle.removeClass('is-hidden');
                text.removeClass('is-hidden');
                img.removeClass('is-hidden');
                link.removeClass('is-hidden');
                imgItem.attr("src", directoryPath + 'search-post-under-page-nav__toggle-img_close_sp.svg');
            } else {
                pageNav.addClass('is-hidden');
                toggle.addClass('is-hidden');
                text.addClass('is-hidden');
                img.addClass('is-hidden');
                link.addClass('is-hidden');
                imgItem.attr("src", directoryPath + 'search-post-under-page-nav__toggle-img_sp.svg');
            }
        } else {
            if (pageNav.hasClass('is-hidden') !== true) {
                pageNav.addClass('is-hidden');
                toggle.addClass('is-hidden');
                toggleOpen.addClass('is-hidden');
                text.addClass('is-hidden');
                img.addClass('is-hidden');
                link.addClass('is-hidden');
            }
        }
    });
    toggleOpen.on('click', function () {
        if (jQuery(window).width() > 1200) {
            if (pageNav.hasClass('is-hidden')) {
                pageNav.removeClass('is-hidden');
                toggle.removeClass('is-hidden');
                toggleOpen.removeClass('is-hidden');
                text.removeClass('is-hidden');
                img.removeClass('is-hidden');
                link.removeClass('is-hidden');
            }
        }
    });

    const currentUrl = $(location).attr('href');
    const linkItem = $(".search-post-under-page-nav__link-item");

    for (let i = 0; i < linkItem.length; i++) {
        let linkItemTag = linkItem[i].getElementsByTagName("a");
        let linkUrl;

        for (let j = 0; j < linkItemTag.length; j++) {
            linkUrl = linkItemTag[j].href;
        };

        if (currentUrl === linkUrl) {
            linkItem[i].classList.add('is-active');
        }
    };
}