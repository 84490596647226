import $ from "jquery";
import { Home } from "./lib/common";
import { autoKana } from "./lib/autoKana";
import { circle } from "./lib/circle";
import { templateElementScrollAction } from "./lib/templateElementScrollAction";
import { headerSearchBtn } from "./lib/headerSearchBtn";
import { searchPostUnderPageNav } from "./lib/searchPostUnderPageNav";
import { searchPostUnderPageNavScroll } from "./lib/searchPostUnderPageNavScroll";

jQuery(function ($) {
    // ツールバーのテンプレートファイル名をクリックで要素へスクロールする
    templateElementScrollAction();

    // キーワード検索のフォームの時
    // headerSearchBtn()

    // 斎場詳細ページ 配下ページメニューの開閉
    searchPostUnderPageNav();

    // 斎場詳細ページ 配下ページメニューのスクロール時の動き
    searchPostUnderPageNavScroll($(window));
});